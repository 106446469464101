import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { selectSubscriptions } from 'store/subscriptions/selectors';

import { DATE_FORMAT } from '../../../../constants/date';

import { sendAnalyticKeepSubscriptionClick } from 'services/analytics';

import { useCancelSubscriptionFlow, useModals, useSelector } from 'hooks';

import { isMainSubscription } from '../../../../helpers/subscriptions';
import { getFormattedDate } from '../../../../helpers/date';

import OverlineButton from 'components/OverlineButton';
import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import Modal from 'components/Modal/Modal';

import * as S from './styled';

import warning from 'assets/img/warning.svg';

const ConfirmationModal = () => {
    const { t } = useTranslation();

    const { openModal, closeModal } = useModals();

    const { discardCancellation } = useCancelSubscriptionFlow();

    const { cancellationCandidates } = useSelector(selectSubscriptions);

    const onClose = () => {
        discardCancellation();
        closeModal();
        sendAnalyticKeepSubscriptionClick();
    };

    const onSubmit = () => {
        openModal(ModalName.CancellationReasonModalOld);
    };

    const isMainProduct = cancellationCandidates && isMainSubscription(cancellationCandidates[0].product_code);

    let title = t('subscription.cancelDialog.additionalSubscriptionTitle', {
        context: cancellationCandidates?.[0].product_code,
    });
    let subtitle = t('subscription.cancelDialog.additionalSubscriptionExtraText');

    if (isMainProduct) {
        title = t('subscription.cancelDialog.title');
        subtitle = t('subscription.cancelDialog.description', {
            date: getFormattedDate(cancellationCandidates?.[0].expired_date, DATE_FORMAT),
        });
    }

    return (
        <Modal paddingY={16} isOpen onClose={closeModal} shouldCloseOnOverlayClick>
            <div data-locator="cancelSubModal">
                <S.Img src={warning} alt="warning" />
                <Text type="h5" center text={title} mb={12} />
                <Text center color="text-secondary" text={subtitle} mb={24} />

                <OutlinedButton
                    dataLocator="cancelSubTrueBtn"
                    onClick={onSubmit}
                    text={t('subscription.text.cancel')}
                    mb={20}
                />
                <OverlineButton
                    dataLocator="cancelSubFalseBtn"
                    onClick={onClose}
                    text={t('subscription.cancelDialog.discard')}
                    mb={4}
                />
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
