import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';
import {
    cancelSubscription__Old,
    fetchDiscountSubscription,
    setCancellationCandidates,
    updateSubscription__Old,
} from 'store/subscriptions/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';

import {
    sendAnalyticCancelSubscriptionClick__Old,
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionCancelSuccess,
} from 'services/analytics';

import { useDispatch, useModals, useSelector } from 'hooks';

import { isMainSubscription } from 'helpers/subscriptions';
import { checkDiscountAllowed } from './helpers__Old';

import { ModalName } from 'components/Modals/types';

import { ISubscription } from 'types/subscription';

const useCancelSubscriptionFlowOld = () => {
    const { t } = useTranslation();
    const { cancellationCandidates, discountSubscription } = useSelector(selectSubscriptions);
    const { country } = useSelector(selectCurrentUser);

    const dispatch = useDispatch();

    const { openModal, closeModal } = useModals();

    const initSubscriptionCancellation = useCallback(
        (candidate: ISubscription) => {
            const { product_code, external_id, discount_type, state } = candidate;
            const isCancellationCandidateMain = isMainSubscription(product_code);
            const isDiscountAllowed = checkDiscountAllowed(country, state);

            if (isCancellationCandidateMain && !discount_type && isDiscountAllowed) {
                dispatch(fetchDiscountSubscription({ discount_type: DISCOUNT_TYPES.base, external_id }));
            }

            openModal(ModalName.ConfirmationModalOld);

            dispatch(setCancellationCandidates([candidate]));
            sendAnalyticCancelSubscriptionClick__Old(external_id);
        },
        [dispatch, openModal, country]
    );

    const discardCancellation = useCallback(() => {
        dispatch(setCancellationCandidates(null));
    }, [dispatch]);

    const onCancelSubscription = useCallback(() => {
        if (!cancellationCandidates) return;

        const { external_id: externalId } = cancellationCandidates[0];

        openModal(ModalName.WaitingModal);

        dispatch(
            cancelSubscription__Old({
                externalId,
                onError: () => {
                    sendAnalyticSubscriptionCancelError(externalId);
                },
                onSuccess: () => {
                    discardCancellation();
                    sendAnalyticSubscriptionCancelSuccess(externalId);
                },
                onCancel: () => {
                    discardCancellation();
                    closeModal();
                },
            })
        );
    }, [cancellationCandidates, discardCancellation, closeModal, openModal, dispatch]);

    const onChangeSubscription = useCallback(() => {
        if (!discountSubscription || !cancellationCandidates) return;

        openModal(ModalName.WithLoaderModal, { title: t('subscription.discountOfferModal.updatingPlan') });
        dispatch(
            updateSubscription__Old({
                product: discountSubscription,
                external_id: cancellationCandidates[0].external_id,
                discount_type: DISCOUNT_TYPES.base,
            })
        );
    }, [discountSubscription, dispatch, openModal, t, cancellationCandidates]);

    return {
        initSubscriptionCancellation,
        cancellationCandidates,
        onCancelSubscription,
        discardCancellation,
        onChangeSubscription,
        discountSubscription,
    };
};

export default useCancelSubscriptionFlowOld;
