import { Box, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import { SUBSCRIPTION_BASE_DISCOUNT } from 'constants/subscriptions';
import { DISCOUNT_OFFER_SCREEN_ID } from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import {
    sendAnalyticChangeSubscriptionClick,
    sendAnalyticCloseOfferClick,
    sendAnalyticDiscardCancelOfferClick,
} from 'services/analytics';

import { useCancelSubscriptionFlow, useDiscountModal } from 'hooks/subscriptions';
import useModals from 'hooks/modals/useModals';

import isMobile from 'helpers/isMobile';

import SubscriptionsSkeleton from 'components/SubscriptionsTab/components/SubscriptionsSkeleton';
import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import Modal from 'components/Modal';
import SubscriptionCard from './components/SubscriptionCard';
import DiscountModalHeader from './components/DiscountModalHeader';

import * as S from './styled';

const DiscountModal = () => {
    const { closeModal, openModal } = useModals();
    const { t } = useTranslation();

    const { onCancelSubscription, onChangeSubscription, discountSubscription } = useCancelSubscriptionFlow();

    const { currentSubscription, currencySign, discountOffer } = useDiscountModal();

    const isDiscountOfferReady = discountOffer && currentSubscription && currencySign;

    const onBackButtonClick = () => {
        sendAnalyticDiscardCancelOfferClick();
        openModal(ModalName.CancellationReasonModal);
    };

    useEffect(() => {
        trackScreenLoad(DISCOUNT_OFFER_SCREEN_ID);
    }, []);

    const changeSubscription = useCallback(() => {
        if (discountSubscription) {
            sendAnalyticChangeSubscriptionClick(discountSubscription.name);
            onChangeSubscription();
        }
    }, [onChangeSubscription, discountSubscription]);

    const onClose = useCallback(() => {
        sendAnalyticCloseOfferClick();
        closeModal();
    }, [closeModal]);

    return (
        <Modal
            fullscreen
            isOpen
            onClose={onClose}
            customStyles={{ background: '#fff', padding: 0 }}
            withRoundedCorners={false}
        >
            <Box paddingBottom={isMobile.any() ? 100 : 0}>
                <DiscountModalHeader onBackButtonClick={onBackButtonClick} closeModal={onClose} />
                <div className="container-sm">
                    <S.DiscountModalContent>
                        <div>
                            <Text
                                medium
                                center
                                mb={12}
                                type="h4"
                                text={t('subscription.discountOfferModal.title', {
                                    percent: SUBSCRIPTION_BASE_DISCOUNT,
                                })}
                                dataLocator="unsubscribeTitle"
                            />
                            <Text
                                center
                                type="large-text"
                                text={t('subscription.discountOfferModal.description')}
                                dataLocator="unsubscribeDescription"
                            />
                        </div>
                        {!isDiscountOfferReady ? (
                            <SubscriptionsSkeleton />
                        ) : (
                            <>
                                <SubscriptionCard
                                    title={t('subscription.discountOfferModal.currentCard.title')}
                                    bgColor="text-secondary"
                                    price={currentSubscription.price}
                                    period={currentSubscription.period}
                                    currencySign={currencySign}
                                />
                                <SubscriptionCard
                                    title={t('subscription.discountOfferModal.discountCard.title', {
                                        percent: SUBSCRIPTION_BASE_DISCOUNT,
                                    })}
                                    bgColor="critical-default"
                                    price={discountOffer.price}
                                    period={currentSubscription.period}
                                    currencySign={currencySign}
                                    oldPrice={currentSubscription.price}
                                    changeSubscription={changeSubscription}
                                    isDiscountCard
                                />
                                <OutlinedButton
                                    onClick={onCancelSubscription}
                                    text={t('subscription.discountOfferModal.btn.cancelSubscription')}
                                    backgroundColor="surface-main"
                                    dataLocator="unsubscribeCancelSubscriptionButton"
                                />
                            </>
                        )}
                    </S.DiscountModalContent>
                </div>
            </Box>
        </Modal>
    );
};

export default DiscountModal;
